import mapFooter from '@/components/footer/map-footer.vue'
import searchHeader from '@/components/header/search-header.vue'
import userLeft from '@/components/left/user-left.vue'
import customerRight from '@/components/right/customer-right.vue'
import dropdown from '@/components/dropdown/dropdown.vue'
const components = {
    install:function(Vue){
		Vue.component('map-footer',mapFooter)
		Vue.component('search-header',searchHeader)
		Vue.component('user-left',userLeft)
		Vue.component('customer-right',customerRight)
		Vue.component('dropdown',dropdown)
    }  //'component-name'这就是后面可以使用的组件的名字，install是默认的一个方法 component-name 是自定义的，我们可以按照具体的需求自己定义名字
}
// 导出该组件
export default components