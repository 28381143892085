export default {
    user: {
        login: 'user/login', //登入
        signout: 'user/signout', //退出
        register: 'user', //注册
        findpwd: 'user/findpwd', //找回密码
        getCertification: 'user/find/certification', //获取实名认证信息
        applyCertification: 'user/apply/certification', //申请实名认证
        modifyPassword: 'user/modifyPassword', //修改密码
        addLand: 'user/insertUserNeedsForLet',//发布土地
        updateLand: 'updateUserNeedsForLet',//修改土地
        deleteLand: 'user/deleteUserNeedsForLet', //删除土地
        addNeeds: 'user/addUserNeeds', //发布需求
        deleteNeeds: 'user/deleteUserNeeds', //删除需求
        findMyLandList:'user/findMyUserNeedsForLetForPage',//我发布的土地
        getMyNeeds: 'user/findMyUserNeeds', //我发布的需求
        getBankCard: 'user/find/bankCard', //查询银行卡
        deleteBankCard: 'user/del/bankCard/', //删除银行卡
        addBankcard:'user/bindBankCard',//新增银行卡
        upload: 'user/uploadUserFile',//上传文件 上传文件 1图片 2视频
        addCollection:'user/add/collection',//用户收藏
        findPageCollection:'user/find/pageCollection',//我的收藏
        deleteCollection:'user/del/collection/', //删除用户收藏
        findCollection:'user/find/collection', // 根据关联ID查询用户收藏
        batchDeleteCollection:'user/del/collectionList',//批量删除用户收藏
        buyLand:'user/userNeedsForLetFlow',//我要购买土地
        createAppointment:'reserve',//创建预约带看
        modifyUserInfo:'user/updateUserBaseInfo',//修改用户基本信息
        getUserInfo:'user/getUserBaseInfo',//查询用户基本信息
        setPassword:'user/setPassword',//设置用户密码
        setTradePassword:'user/setTradePassword',//设置用户交易密码
        modifyTradePassword:'user/modifyTradePassword',//修改用户交易密码
        findTradePassword:'user/findTradePasswordByMobile',//找回交易密码
        addcompany:'user/apply/company', //企业认证
        findcompany:'user/find/company',//获取企业认证信息
        findUserContract:'user/findContractByUserId',//查询我发布的需求以及合同
    },
    personal: {
        upload: 'user/uploadUserNeedsFile',//上传文件 1图片 2视频
    },
    test: {
        infoMock: 'test/info', //详情
        listMock: 'test/list', //列表
    },
    expand: {
        getLink: 'portalExpand/links/find', //查询友情链接
    },
    finance: {
        investList: 'portal/finance/invest/find', //理财产品
        loanList: 'portal/finance/loan/find', //贷款产品
        partnerList:'portal/finance/partner/find' //合作伙伴
    },
    ask: {
        getClass: 'qaType/findAll', //问答分类
        getQuestionList: 'qaa/question/find', //问题列表
        getHotPeople: 'qaa/question/findHotPeople', //活跃用户
        getHotQuestion: 'qaa/question/findHotQuestion', //热门问题
        addQuestion: 'qaa/question', //添加问题
        getAnswerList: 'qaa/answer/find', //回答列表
        adoptAnswer: 'qaa/answer/adoption', //采纳回答
        addAnswer: 'qaa/answer', //添加回答
        getTotalSolve: 'qaa/question/findTotalSolve', // 解决问题总数
        getMyQuestion: 'qaa/question/findMyQuestion', //我的问题
        getMyAnswer: 'qaa/answer/findMyAnswer', //我的回答
        getQuestionStatistics: 'qaa/question/findUserQuestionInformation', //我的问题统计
        getAnswerStatistics: 'qaa/answer/findUserAnswerInformation' //我的回答统计
    },
    area: {
        getList: 'area/findAll',//查询所有地区
    },
    news: {
        getDetail: 'content', //新闻详情
        getList: 'content/find', //新闻列表
    },
    dict: {
        findDickTypeName:'dictionary/type/findTypeForDetailData', //根据名称获取字典明细
        getLandUseType: 'landUseType/findAll', //获取土地用途
        getLandTags:'userNeedTags/findAll'  //查询标签
    },
    supply:{
        getNeedsForLetList:'user/findUserNeedsForLet', //查询发布土地信息分页查询
        getLandDetail:'user/findUserNeedsForLetById', //查询土地详情
        getNeedsList: 'user/findUserNeeds', //需求大厅
        getNeedsDetail:'user/findUserNeedById', //查询需求详情
    },
    banner:{
        findBanner:'banner/findAll' //查询所有广告
    },
    reserve:{
        findReserveRecordById:'reserve/findReserveRecordByBizId/', //查询招租信息下的预约带看记录
        findReserveRecordList:'reserve/findUserReserveRecordForPage',//分页查询当前登录人的预约记录
        payForReserve:'reserve/payForReserveRecord/', // 预约带看订单支付
        cancelReserve:'reserve/cancelReserveRecord/',// 取消预约带看
    },
    order:{
        findOrder:'userOrder/findUserOrderByBizId/',//查询订单
        findUserOrder:'userOrder/findPortalUserOrderForPage',//查询我的订单分页
        cancelOrder:'userOrder/cancelOrder/',//取消订单
        payOrder:'userOrder/payForPortalUserOrder/',//订单支付
    }

}