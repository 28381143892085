<template>
	<div class="main_cont_box clearfix">
		<div class="main_cont_leftBox">
			<div class="tk-box">
				<dl>
					<dd :class="{'this' : currentActive == 0}">
						<router-link to="/user">
							<img class="u-icon" src="@/assets/icon/home-active.png"/>用户中心
						</router-link>
					</dd>
				</dl>
				<dl>
					<dt>我的土地</dt>
					<dd :class="{'this' : currentActive == 1}">
						<router-link to="/user/land">
							<img v-if="currentActive == 1" class="u-icon" src="@/assets/icon/land-manage-active.png"/>
							<img v-else class="u-icon" src="@/assets/icon/land-manage.png"/>
							土地管理
						</router-link>
					</dd>
					<dd :class="{'this' : currentActive == 13}">
						<router-link to="/user/land/machinery">
							<img v-if="currentActive == 13" class="u-icon" src="@/assets/icon/u-njj-active.png"/>
							<img v-else class="u-icon" src="@/assets/icon/u-njj.png"/>
							农机具管理
						</router-link>
					</dd>
				</dl>
				<dl>
					<dt>我的需求</dt>
					<dd :class="{'this' : currentActive == 2}">
						<router-link to="/user/needs">
							<img v-if="currentActive == 2" class="u-icon" src="@/assets/icon/needs-active.png"/>
							<img v-else class="u-icon" src="@/assets/icon/needs.png"/>
							登记的需求
						</router-link>
					</dd>
					<dd :class="{'this' : currentActive == 3}">
						<router-link to="/user/needs/pushland">
							<img v-if="currentActive == 3" class="u-icon" src="@/assets/icon/land-rec-active.png"/>
							<img v-else class="u-icon" src="@/assets/icon/land-rec.png"/>
							推荐的土地
						</router-link>
					</dd>
					<dd :class="{'this' : currentActive == 12}">
						<router-link to="/user/needs/appointment">
							<img v-if="currentActive == 12" class="u-icon" src="@/assets/icon/u-yy-active.png"/>
							<img v-else class="u-icon" src="@/assets/icon/u-yy.png"/>
							我的预约
						</router-link>
					</dd>
				</dl>
				<dl>
					<dt>订单管理</dt>
					<dd :class="{'this' : currentActive == 4}">
						<router-link to="/user/order/index">
							<img v-if="currentActive == 4" class="u-icon" src="@/assets/icon/order-active.png"/>
							<img v-else class="u-icon" src="@/assets/icon/order.png"/>
							我的订单
						</router-link>
					</dd>
					<dd :class="{'this' : currentActive == 5}">
						<router-link to="/user/order/capital">
							<img v-if="currentActive == 5" class="u-icon" src="@/assets/icon/cash-account-active.png"/>
							<img v-else class="u-icon" src="@/assets/icon/cash-account.png"/>
							资金账户
						</router-link>
					</dd>
				</dl>
				<dl>
					<dt>账户安全</dt>
					<dd :class="{'this' : currentActive == 7}">
						<router-link to="/user/account">
							<img v-if="currentActive == 7" class="u-icon" src="@/assets/icon/infomation-active.png"/>
							<img v-else class="u-icon" src="@/assets/icon/infomation.png"/>
							账户信息
						</router-link>
					</dd>
				</dl>
				<dl>
					<dt>互动平台</dt>
					<dd :class="{'this' : currentActive == 8}">
						<router-link to="/user/ask">
							<img v-if="currentActive == 8" class="u-icon" src="@/assets/icon/ask-active.png"/>
							<img v-else class="u-icon" src="@/assets/icon/ask.png"/>
							我的问答
						</router-link>
					</dd>
					<dd :class="{'this' : currentActive == 9}">
						<router-link to="/user/message">
							<img v-if="currentActive == 9" class="u-icon" src="@/assets/icon/message-active.png"/>
							<img v-else class="u-icon" src="@/assets/icon/message.png"/>
							我的消息
						</router-link>
					</dd>
				</dl>
				<dl>
					<dt>我的收藏</dt>
					<dd :class="{'this' : currentActive == 10}">
						<router-link to="/user/collection/land">
							<img v-if="currentActive == 10" class="u-icon" src="@/assets/icon/land-tag-active.png"/>
							<img v-else class="u-icon" src="@/assets/icon/land-tag.png"/>
							收藏土地
						</router-link>
					</dd>
					<dd :class="{'this' : currentActive == 11}">
						<router-link to="/user/collection/news">
							<img v-if="currentActive == 11" class="u-icon" src="@/assets/icon/collection-active.png"/>
							<img v-else class="u-icon" src="@/assets/icon/collection.png"/>
							收藏资讯
						</router-link>
					</dd>
				</dl>
			</div>
		</div>
	</div>	
</template>

<script>
	export default {
		name: 'map-footer',
		props: {
			currentActive: {
				type: [Number, String],
				default: -1
			}
		}
	}
</script>

<style scoped>
	.main_cont_leftBox {
		width: 184px;
		float: left;
		background: #fff;
		border-radius: 8px;
		position: relative;
		z-index: 2;
		height: 100% !important;
	}
	
	/*hy-左侧导航-*/
	.tk-box {
		display: block;
		padding: 0px 10px;
	}
	
	.tk-box dl {
		display: block;
		border-bottom: 1px solid #eee;
		padding: 5px 0px;
		font-size: 14px
	}
	
	.tk-box dl:last-child {
		border: none;
	}
	
	.tk-box dt,
	.tk-box dd {
		display: block;
	}
	
	.tk-box dd {
		line-height: 40px;
		height: 40px;
		overflow: hidden;
		width: 100%;
		position: relative;
		margin-left: -10px;
	}
	
	.tk-box dt {
		padding-left: 16px;
		line-height: 30px;
		color: #a0a0a0;
	}
	
	.tk-box dd a {
		display: block;
		color: #666
	}
	
	.tk-box dd:hover {
		background-color: #f9f9f9;
		width: 184px;
	}
	
	
	.tk-box dd em.red-dot {
		position: absolute;
		top: 10px;
		left: 130px;
		width: 6px;
		height: 6px;
		border-radius: 3px;
		background: #d93821;
		display: block;
	}
	
	.tk-box dd.this {
		background-color: #f9f9f9;
		width: 184px;
		padding: 0px 10px 0px 7px;
		position: relative;
		margin-left: -10px;
		border-left: 3px solid #00cc00;
	}
	
	.tk-box dd.this a {
		color: #00cc00;
	}

	
	.tk-box dd.this .u-icon {
		margin:0px 10px 0 15px;
		width: 24px; 
		height: 24px;
	}

	dd,dl {
		margin-bottom: 0px;
		font-weight: unset;
	}
</style>
