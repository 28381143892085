<template>
  <div v-if="isShow" class="city-select-warp" :style="{left: left + 'px',top: top + 'px'}">
    <div class="city-select-tab">
      <a
        @click="current = 0"
        class="tab-china"
        :class="{ current: current == 0 }"
        href="javascript:;"
        >省份</a
      >
      <a
        @click="current = 1"
        class="tab-china"
        :class="{ current: current == 1 }"
        href="javascript:;"
        >城市</a
      >
      <a
        v-if="isDistrictShow"
        @click="current = 2"
        class="tab-china"
        :class="{ current: current == 2 }"
        href="javascript:;"
        >区县</a
      >
    </div>
    <div class="city-select-content">
      <div
        v-show="current == 0"
        class="city-select city-province"
        style="display: block"
      >
        <dl v-for="(g, i) in groupProvince" :key="i">
          <dt>{{ g.name }}</dt>
          <dd>
            <a
              v-for="(p, j) in g.p_array"
              :key="j"
              @click="onProvinceClick(p)"
              href="javascript:;"
              :class="{ current: chooseValue == p }"
              >{{ p }}</a
            >
          </dd>
        </dl>
      </div>
      <div v-show="current == 1" class="city-select city-city">
        <dl>
          <dd>
            <a
              v-for="(c, i) in getCity"
              :key="i"
              @click="onCityClick(c)"
              href="javascript:;"
              :class="{ current: choose.city.id == c.id }"
              >{{ c.name }}</a
            >
          </dd>
        </dl>
      </div>
      <div v-show="current == 2 && isDistrictShow" class="city-select city-district">
        <dl>
          <dd>
            <a
              v-for="(a, i) in getArea"
              :key="i"
              @click="onAreaClick(a)"
              href="javascript:;"
              :class="{ current: choose.area.id == a.id }"
              >{{ a.name }}</a>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Number,
      default: 0
    },
    top: {
      type: Number,
      default: 44
    },
    isDistrictShow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isAuto: false,
      current: 0,
      groupProvince: [
        {
          name: "A-G",
          p_array: [
            "北京",
            "安徽",
            "福建",
            "广东",
            "广西",
            "重庆",
            "贵州",
            "甘肃",
            "澳门",
          ],
        },
        {
          name: "H-K",
          p_array: [
            "湖南",
            "河北",
            "吉林",
            "黑龙江",
            "江苏",
            "江西",
            "河南",
            "湖北",
            "海南",
          ],
        },
        {
          name: "L-S",
          p_array: [
            "山西",
            "内蒙古",
            "辽宁",
            "上海",
            "山东",
            "四川",
            "陕西",
            "宁夏",
            "青海",
          ],
        },
        {
          name: "T-Z",
          p_array: ["天津", "浙江", "云南", "西藏", "新疆", "台湾", "香港"],
        },
      ],
      chooseValue: "",
      choose: {
        province: {},
        city: {},
        area: {},
      }
    };
  },
  computed: {
    ...mapGetters(["area"]),
    getCity() {
      if (this.area) {
        let p = this.area.province.find((x) =>
          x.name.includes(this.chooseValue)
        );
        this.choose.province = p;
        let c = this.area.city.filter(
          (x) => x.level == 2 && x.parentCode == p.code
        );
        if (c.length == 1) {
          this.choose.city = c[0];
          if(this.isAuto) {
            if(this.isDistrictShow) {
              this.current = 2;
            } else {
              this.$emit('onChoose',this.choose)
            }
          }
        } else {
            if(this.isAuto) {
                this.current = 1;
            }
        }
        this.isAuto = false;
        return c;
      }
      return [];
    },
    getArea() {
      if (this.area && this.choose.city) {
        let a = this.area.area.filter(
          (x) => x.level == 3 && x.parentCode == this.choose.city.code
        );
        return a;
      }
      return [];
    }
  },
  model: {
    prop: 'isShow',
    event: 'hidden'
  },
  mounted() {
    document.addEventListener('click',this.setBoxStatus,true);
  },
  destroyed() {
    document.removeEventListener('click',this.setBoxStatus,true);
  },
  methods: {
      setBoxStatus(e) {
        if(e.path.findIndex(x=> typeof x.className == 'string' && x.className.includes('areabox')) < 0 && this.isShow) {
          
          this.$emit('hidden', false)
        }
      },
      onProvinceClick(p) {
        this.chooseValue = p;
        this.isAuto = true;
      },
      onCityClick(c) {
        this.choose.city = c;
        if(this.isDistrictShow) {
          this.current = 2;
        } else {
          this.$emit('onChoose',this.choose)
        }
      },
      onAreaClick(a) {
          this.choose.area = a;
          this.$emit('onChoose',this.choose)
      }
  }
};
</script>

<style scoped>
@import url("~@/assets/css/city.css");
</style>
