import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    // mode: 'history',
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: '首页',
            component: () => import('./views/home/home.vue')
        },
        {
            path: '/news',
            name: '新闻首页',
            component: () => import('./views/news/home.vue')
        },
        {
            path: '/news/lists',
            name: '新闻列表',
            component: () => import('./views/news/lists.vue')
        },

        {
            path: '/news/detail',
            name: '新闻详情',
            component: () => import('./views/news/detail.vue')
        },
        {
            path: '/farmland',
            name: '农用地',
            component: () => import('./views/supply/farmland.vue')
        },
        {
            path: '/estimate',
            name: '土地评估',
            component: () => import('./views/supply/estimate.vue')
        },
		{
		    path: '/buildland',
		    name: '建设用地',
		    component: () => import('./views/supply/buildland.vue')
		},
		{
		    path: '/farmhouse',
		    name: '农房',
		    component: () => import('./views/supply/farmhouse.vue')
		},
        {
            path: '/woodland',
            name: '林地',
            component: () => import('./views/supply/woodland.vue')
        },
        {
            path: '/fineland',
            name: '精品土地展',
            component: () => import('./views/supply/fineland.vue')
        },
        {
            path: '/otherland',
            name: '其他土地',
            component: () => import('./views/supply/otherland.vue')
        },
		{
		    path: '/farmmachinery',
		    name: '农机具',
		    component: () => import('./views/supply/farmmachinery.vue')
		},
		{
		    path: '/supply/detail',
		    name: '供应详情',
		    component: () => import('./views/supply/detail.vue')
		},
        {
		    path: '/supply/index',
		    name: '供应列表',
		    component: () => import('./views/supply/index.vue')
		},
        {
            path: '/supply/map',
            name: '地图找地',
            component: () => import('./views/supply/map.vue')
        },
        {
            path: '/needs',
            name: '土地需求',
            component: () => import('./views/needs/list.vue')
        },
        {
            path: '/needs/makemaps',
            name: '预约测绘',
            component: () => import('./views/needs/makemaps.vue')
        },
        {
            path: '/bi',
            name: '土地数据',
            component: () => import('./views/bi/index.vue')
        },
        {
            path: '/needs/detail',
            name: '需求详情',
            component: () => import('./views/needs/detail.vue')
        },
        {
            path: '/ask',
            name: '土流问答',
            component: () => import('./views/ask/list.vue')
        },
        {
            path: '/ask/detail',
            name: '问答详情',
            component: () => import('./views/ask/detail.vue')
        },
        {
            path: '/ask/add',
            name: '我要提问',
            component: () => import('./views/ask/add.vue')
        },
        {
            path: '/finance',
            name: '金融服务',
            component: () => import('./views/finance/index.vue')
        },
        {
            path: '/login',
            name: '登录',
            component: () => import('./views/user/login/login.vue')
        },
        {
            path: '/register',
            name: '注册',
            component: () => import('./views/user/register/register.vue')
        },
        {
            path: '/findpwd',
            name: '找回密码',
            component: () => import('./views/user/account/password/find.vue')
        },
        {

            path: '/user',
            name: '用户中心',
            component: () => import('./views/user/index/index.vue')
        },
        {
            path: '/user/account',
            name: '账户信息',
            component: () => import('./views/user/account/index.vue')
        },
        {
            path: '/user/account/certification/personal',
            name: '实名认证',
            component: () => import('./views/user/account/certification/personal.vue')
        },
        {
            path: '/user/account/certification/company',
            name: '企业认证',
            component: () => import('./views/user/account/certification/company.vue')
        },
        {
            path: '/user/account/password/setting',
            name: '设置密码',
            component: () => import('./views/user/account/password/setting.vue')
        },
        {
            path: '/user/account/password/modify',
            name: '修改密码',
            component: () => import('./views/user/account/password/modify.vue')
        },
        {
            path: '/user/account/bankcard',
            name: '银行卡',
            component: () => import('./views/user/account/bankcard/list.vue')
        },
        {
            path: '/user/account/bankcard/add',
            name: '添加银行卡',
            component: () => import('./views/user/account/bankcard/add.vue')
        },
        {
            path: '/user/account/transpassword/setting',
            name: '设置交易密码',
            component: () => import('./views/user/account/transpassword/setting.vue')
        },
        {
            path: '/user/account/transpassword/modify',
            name: '修改交易密码',
            component: () => import('./views/user/account/transpassword/modify.vue')
        },
        {
            path: '/user/account/transpassword/find',
            name: '找回交易密码',
            component: () => import('./views/user/account/transpassword/find.vue')
        },
        {
            path: '/user/account/mobile/bind',
            name: '绑定手机',
            component: () => import('./views/user/account/mobile/bind.vue')
        },
        {
            path: '/user/account/mobile/update',
            name: '更换手机',
            component: () => import('./views/user/account/mobile/update.vue')
        },
        {
            path: '/user/ask',
            name: '我的问答',
            component: () => import('./views/user/ask/index.vue')
        },
        {
            path: '/user/message',
            name: '我的消息',
            component: () => import('./views/user/message/index.vue')
        },
        {
            path: '/user/collection/land',
            name: '收藏土地',
            component: () => import('./views/user/collection/land.vue')
        },
        {
            path: '/user/collection/news',
            name: '收藏资讯',
            component: () => import('./views/user/collection/news.vue')
        },
        {
            path: '/user/land/add',
            name: '发布土地',
            component: () => import('./views/user/land/add.vue')
        },
        {
            path: '/user/land',
            name: '土地管理',
            component: () => import('./views/user/land/list.vue')
        },
        {
            path: '/user/land/machinery',
            name: '农机具管理',
            component: () => import('./views/user/land/machinery.vue')
        },
        {
            path: '/user/order/capital',
            name: '资金账户',
            component: () => import('./views/user/order/capital.vue')
        },
        {
            path: '/user/order/index',
            name: '我的订单',
            component: () => import('./views/user/order/index.vue')
        },
        {
            path: '/user/order/detail',
            name: '土地交易订单详情',
            component: () => import('./views/user/order/detail.vue')
        },
        {
            path: '/user/order/appointmentdetail',
            name: '预约带看订单详情',
            component: () => import('./views/user/order/appointmentdetail.vue')
        },
        {
            path: '/user/needs',
            name: '我的需求',
            component: () => import('./views/user/needs/list.vue')
        },
        {
            path: '/user/needs/pushland',
            name: '推荐的土地',
            component: () => import('./views/user/needs/pushland.vue')
        },
        {
            path: '/user/needs/appointment',
            name: '我的预约',
            component: () => import('./views/user/needs/appointment.vue')
        },
        {
            path: '/user/needs/add',
            name: '登记需求',
            component: () => import('./views/user/needs/add.vue')
        },
        {
            path: '/servicecentre/index',
            name: '服务中心',
            component: () => import('./views/servicecentre/index.vue')
        },
        {
            path: '/servicecentre/joinus',
            name: '加入我们',
            component: () => import('./views/servicecentre/joinus.vue')
        },
        {
            path: '/help',
            name: '帮助中心',
            component: () => import('./views/help/index.vue')
        },
        {
            path: '/aboutus',
            name: '关于我们',
            component: () => import('./views/aboutus/index.vue')
        },
        {
            path: '/vip',
            name: 'vip会员',
            component: () => import('./views/user/vip/vip.vue')
        },
        {
            path: '/404',
            name: '494',
            component: () => import('./views/error/notfound.vue')
        },
        {
            path: '*',
            redirect: '/404'
        }
    ]
})
