import Vue from 'vue'
import App from './App.vue'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router'
import store from './store';
import model from '@/assets/js/model.js'
import axios from '@/assets/js/axios.js'
import api from '@/assets/js/api.js'
import BaiduMap from 'vue-baidu-map'
import test from '@/assets/js/test.js'
import { Lazyload } from 'vant';
import myComponents from '@/components/install'
import share from 'vue-social-share'
import 'vue-social-share/dist/client.css';

Vue.prototype.$store = store;
Vue.prototype.$http = axios;
Vue.prototype.$model = model;
Vue.prototype.$api = api; 
Vue.prototype.$test = test; 
Vue.prototype.$global = {
  picBaseUrl: 'http://47.99.63.89:8080/',
}; 

Vue.use(IconsPlugin).use(share).use(BootstrapVue).use(myComponents).use(Vant).use(Lazyload).use(BaiduMap, { ak: 'N8MejnT0DbwtExckQu1LPSlbvWbUFGqW' });

router.afterEach(()=>{
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});

if (!process.env.VUE_APP_URL) {    // 判断是否为mock模式
  require('./mock/index.js')
}

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
