<template>
    <div :class="'box ' + className" :style="{width: width + 'px'}">
        <input @click="isShow = !isShow" :value="choose.text" class="form-control ellipsis cursor-hand" type="text" :placeholder="placeholder" readonly="readonly">
        <em>
            <b-icon  @click="isShow = !isShow" icon="chevron-down"></b-icon>
        </em>
        <div v-if="isShow && datas.length > 0" class="dropdown-items py-2" :style="{width: width + 'px'}">
            <div @click="onClick(c)" class="dropdown-option p-2 px-4"  v-for="(c, i) in datas" :key="i">
                 <a
                    href="javascript:;"
                    :class="{ 'text-theme': choose.id == c.id }"
                    >{{ c.text }}</a>
            </div> 
        </div>
    </div>
</template>

<script>
export default {
  props: {
    placeholder: {
        type: String,
        default: '',
    },
    datas: {
      type: Array,
      default: [],
    },
    active: {
      type: [String, Number],
      default: ''
    },
    width: {
       type: Number,
       default: 200
    }
  },
  watch: {
    'datas': 'onUpdate'
  },
  data() {
    return {
      className: 'box',
      isShow: false,  
      chooseValue: "",
      choose: {
        id: '',
        text: ''
      }
    };
  },
  mounted() {
    this.onUpdate();
    this.className = this.getGenerateUUID();
    document.addEventListener('click',this.setBoxStatus,true);
  },
  destroyed() {
    document.removeEventListener('click',this.setBoxStatus,true);
  },
  methods: {
    onClick(c) {
      this.choose = c;
      this.isShow = false;
      this.$emit('onChange',this.choose);
    },
    setBoxStatus(e) {
      if(e.path.findIndex(x=> typeof x.className == 'string' && x.className.includes(this.className)) < 0) {
        this.isShow = false;
      }
    },
    getGenerateUUID() {
      var d = new Date().getTime();
      if (window.performance && typeof window.performance.now === "function") {
          d += performance.now(); //use high-precision timer if available
      }
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          var r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
      return uuid;
    },
    onUpdate() {
      if(this.datas) {
        let m = this.datas.find(x=>x.id == this.active);
        if(m) {
          this.choose = this.datas.find(x=>x.id == this.active);
        } 
      }
    }
  }
};
</script>

<style scoped>
    .dropdown-items {
        position: absolute;
        background-color: #fff;
        border: 1px solid #ced4da;
        top:42px;
        z-index: 99;
    }
    .dropdown-option:hover {
      background-color: #f8f9fa;
    }
</style>
