<template>
	<div>
		<footer v-if="isMapShow" class="map-footer clearfix">
			<div class="container">
				<div class="row">
					<div class="footer-txt w-70">
						<dl>
							<dt>特色功能</dt>
							<dd>
								<router-link to="/user/land/add" target="_blank">发布土地</router-link>
								<router-link to="/user/needs/add" target="_blank">我要找地</router-link>
								<router-link to="/servicecentre/index" target="_blank">申请合作</router-link>
								<router-link to="/finance">金融服务</router-link>
							</dd>
						</dl>
						<dl>
							<dt>土地知识</dt>
							<dd>
								<router-link :to="'/news/lists?id=' + getNewsClassId('国家政策')" target="_blank">国家政策</router-link>
								<router-link to="/ask" target="_blank">土地问答</router-link>
								<router-link :to="'/news/lists?id=' + getNewsClassId('土地合同')" target="_blank">土地合同</router-link>
							</dd>
						</dl>
						<dl>
							<dt>资讯中心</dt>
							<dd>
								<router-link :to="'/news/lists?id=' + getNewsClassId('土地快讯')" target="_blank">土地快讯</router-link>
								<router-link :to="'/news/lists?id=' + getNewsClassId('农业快讯')" target="_blank">农业快讯</router-link>
								<router-link :to="'/news/lists?id=' + getNewsClassId('流转资讯')" target="_blank">流转资讯</router-link>
								<router-link :to="'/news/lists?id=' + getNewsClassId('土地百科')" target="_blank">土地百科</router-link>
							</dd>
						</dl>
						<dl>
							<dt>帮助中心</dt>
							<dd>
								<router-link to="/help?title=网站公告" target="_blank">网站公告</router-link>
								<router-link to="/help?title=我要找地" target="_blank">我要找地</router-link>
								<router-link to="/help?title=我要卖地" target="_blank">我要卖地</router-link>
								<router-link to="/help?title=网站操作流程" target="_blank">网站操作流程</router-link>
							</dd>
						</dl>
						<dl>
							<dt>关于我们</dt>
							<dd>
								<router-link to="/aboutus?title=企业概况" target="_blank">企业概况</router-link>
								<router-link to="/aboutus?title=联系我们" target="_blank">联系我们</router-link>
								<router-link to="/aboutus?title=产品展示" target="_blank">产品展示</router-link>
								<router-link to="/aboutus?title=企业动态" target="_blank">企业动态</router-link>
								<router-link to="/aboutus?title=商业模式" target="_blank">商业模式</router-link>
								<router-link to="/aboutus?title=服务范围" target="_blank">服务范围</router-link>
							</dd>
						</dl>
						<dl>
							<dt>联系我们</dt>
							<dd>
								<p>市场合作:0794-8269907</p>
								<p>商务合作:0794-8269907</p>
								<p>企业邮箱:jxry0706@163.com</p>
								<!--<div class="dl-sina"><i class="custom-icon icon-weibo"></i>
									<div class="pull-left">关注官方微博<br><a href="javascript:;"
											rel="nofollow" >@农村综合产业服务</a></div>
								</div>-->
							</dd>
						</dl>
					</div>
					<div class="float-right w-30">
						<!--<div class="footer-code mr-2">
							<img src="https://statics.tuliu.com/web/images/qr_app.png?ver=VV20210708101701">
							<p>扫一扫下载土流APP</p>
						</div>
						<div class="footer-code">
							<img src="https://statics.tuliu.com/web/images/qr_wechat.png?ver=VV20210708101701">
							<p>官方微信 关注有礼</p>
						</div>-->
					</div>
				</div>
			</div>
		</footer>
		<footer class="icp-footer text-white">
			<div class="container h-100">
				<div class="row h-100 align-items-center text-left">
					<div class="float-left text-left w-80">
						<span class="pr-4">
							©2021版权所有 农村综合产权交易服务平台
						</span>
						<a href="https://beian.miit.gov.cn/" class="pr-4 text-white" target="_blank">
							赣ICP备2021008389号-1
						</a>
						<span class="pr-4">
							增值电信业务经营许可证:赣B2-20150090
						</span>
						<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=36100202000344" class="pr-4 text-white" target="_blank">
							<img src="@/assets/icon/beian.png"/> 赣公网安备 36100202000344
						</a>
					</div>
					<div class="phone float-right w-20">
						<div class="float-right ml-1">
							<div class="text-left f-20">0794-8269907</div>
							<div>周一至周六（8:00-18:00）</div>
						</div>
						<div class="float-right">
							<b-icon class="h1 m-1" icon="telephone" aria-hidden="true" background-color="#ffffff"></b-icon>
						</div>

						<div class="clearfix"></div>
					</div>
					<div class="clearfix"></div>
				</div>
			</div>
		</footer>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	export default {
		computed: {
			...mapGetters(['dict']),
			getNewsClassId() {
				return function (value) {
					if(this.dict) {
						if(this.dict.newsClass) {
							let n = this.dict.newsClass.find(x=> x.text == value);
							if(n) {
								return n.id;
							}
						}
					}
					return '';
				}
			}
		},
		props: {
			isMapShow: {
				type: Boolean,
				default: true
			},
		},
		
	}
</script>

<style scoped>
	.icp-footer {
		background-color: #212121;
		font-size: 12px;
		height: 80px;
		padding: 0 15px 0 15px;
	}

	.map-footer {
		background: #2E2E30;
		padding: 35px 15px 0 15px;
	}
	
	.map-footer p {
		margin-bottom: 0px;
	}

	.map-footer .footer-txt {
		float: left;
		color: #949393;
		line-height: 20px;
		text-align: left;
	}

	.map-footer .footer-txt dl {
		width: 120px;
		font-size: 12px;
		float: left
	}

	.map-footer .footer-txt dl:last-child {
		width: 160px
	}

	.map-footer .footer-txt dl dt {
		color: #FFFFFF;
		margin-bottom: 10px;
		font-weight: 700
	}

	.map-footer .footer-txt dl dd {
		line-height: 20px
	}

	.map-footer .footer-txt dl a {
		display: block;
		color: #949393
	}

	.map-footer .footer-txt dl a:hover {
		color: #FFFFFF
	}

	.map-footer .footer-txt .dl-sina {
		margin-top: 25px;
		height: 32px;
		line-height: 17px
	}

	.map-footer .footer-txt .dl-sina i {
		width: 34px;
		height: 34px;
		text-align: center;
		line-height: 34px;
		font-size: 34px;
		float: left;
		margin-right: 10px
	}

	.map-footer .footer-code {
		float: right;
		text-align: center;
		font-size: 14px;
		color: #888;
		margin-left: 30px
	}

	.map-footer .footer-code img {
		width: 129px;
		height: 129px;
		margin-bottom: 10px
	}

	@media screen and (max-width:1200px) {
		.map-footer,
		.icp-footer {
			width: 1140px;
			max-width: 1140px;
		}
		.container,
		.map-footer .row ,.icp-footer .row{
			width: 1110px;
			max-width: 1110px;
		}
	}
</style>
