import Vue from 'vue'

const state = {
    typeList: []
}

const mutations = {
    SET_LIST: (state, list) => {
        state.typeList=list
    },
    SET_MEMBER: (state, member) => {

    }
}

const actions = {
    getTypeList({commit, state}) {
        Vue.prototype.$http.post(Vue.prototype.$api.information.findInfoclass).then(x => {
            commit("SET_LIST",x.data)
        });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
