<template>
	<header>
		<div class="top-header">
			<div class="container">
				<div class="row">
					<div class="col-8 f-14 p-0">
						<ul>
							<li v-if="isSnShow == false">
								<router-link to="/">
									<img src="@/assets/img/logo-wz.png" style="height:30px">
								</router-link>
							</li>	
							<li>
								<a href="javascript:void(0)">
									<b-icon icon="telephone-fill"></b-icon>
									0794-8269907
								</a>
							</li>
							<li>
								<span class="text-gray">|</span>
							</li>
							<li>
								<a href="javascript:void(0)">
									<i class="custom-icon icon-wechat"></i>
									官方微信
								</a>
							</li>
							<li>
								<span class="text-gray">|</span>
							</li>
							<li>
								<a href="javascript:void(0)">
									<i class="custom-icon icon-wechat"></i>
									合作微信
								</a>
							</li>
							<li>
								<span class="text-gray">|</span>
							</li>
							<li class="">
								<router-link to="/help" target="_blank">
									<b-icon icon="question-circle-fill"></b-icon>
									帮助中心
								</router-link>
							</li>
							<li>
								<span class="text-gray">|</span>
							</li>
							<li>
								<router-link to="/aboutus" target="_blank">
									<b-icon icon="info-circle-fill"></b-icon>
									关于我们
								</router-link>
							</li>
						</ul>
					</div>
					
					<div class="col-4 f-14 p-0">
						<ul v-if="user" class="float-right">
							<li class="mr-1">
                				<img v-if="user.headPic" :src="user.headPic"  class="headpic" />
								<img v-else src="@/assets/img/headpic.png" class="headpic">
							</li>
							<li>
								{{user.nickName}}
							<li>
								<router-link to="/user">
									个人中心
								</router-link>
							</li>
							<li style="line-height:normal;">
								<router-link to="/vip">
									<span style="line-height: 45px;">开通</span>
									<i class="custom-icon icon-vip f-18"></i>
								</router-link>
							</li>
							<li>
								<a @click="signout" href="javascript:;">
									退出
								</a>
							</li>
						</ul>
						<ul v-else class="float-right">
							<li class="mr-1">
								<i class="custom-icon icon-user f-20"></i>
							</li>
							<li>
								<router-link to="/login">
									登录
								</router-link>
							</li>
							<li><span class="text-gray">|</span></li>
							<li class="mr-4">
								<router-link to="/register">
									注册
								</router-link>
							</li>
						</ul>
					</div>

				</div>
			</div>
		</div>
		<div v-if="isSnShow" class="search-header">
			<div class="container">
				<div class="row">
					<div class="col-12 p-0 clearfix d-flex align-items-center">
						<router-link class="float-left" to="/">
							<img src="@/assets/img/logo-wz.png" style="width: 220px;">
						</router-link>
						<div class="text-gray float-left m-3">|</div>
						<div class="text-green float-left text-truncate" style="max-width:50px">{{location ? location.city : '全国'}}</div>
						<div class="text-gray-777 float-left ml-2 cursor-hand" @click="isAreaShow = !isAreaShow">
							[切换城市]
						</div>
						<div class="areabox">
							<city v-model="isAreaShow" :left="315" :top="50" :isDistrictShow="false" @onChoose="onChooseArea"></city>
						</div>
						
						<div class="search-group clearfix">
							<div class="float-left" style="background-color: #EEEEEE;padding: 0 15px;">
								<div style="line-height: 36px;">
									找土地
								</div>
							</div>
							<div class="float-right">
								<b-form-input v-model="keyword" class="search-input" placeholder="请输入关键词 如: 土地标题、城市、土地类型" maxlength='20'
									autocomplete='off' ></b-form-input>
							</div>
							<div class="clearfix"></div>
						</div>
						<div class="float-left ml-2 mr-2">
							<div @click="onSearch" class="s-btn cursor-hand">搜索</div>
						</div>
						<div class="land-btn text-theme float-right">
							<router-link to="/user/land/add">免费发布土地</router-link>
							<span class="mr-3 ml-3">|</span>
							<router-link to="/user/needs/add">发布需求</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="isSnShow" class="nav-con">
			<div class="nav-content clearfix">
				<ul class="nav-ul">
					<li class="highlight item" :class="{ 'active-menu' : currentActive == 0}">
						<router-link to="/">首页</router-link>
					</li>
					<li class="highlight item" :class="{ 'active-menu' : currentActive == 1}">
						<router-link to="/farmland">农用地</router-link>
					</li>
					<!-- <li class="highlight item " :class="{ 'active-menu' : currentActive == 2}">
						<router-link to="/buildland">建设用地</router-link>
					</li> -->
					<li class="highlight item " :class="{ 'active-menu' : currentActive == 3}">
						<router-link to="/farmhouse">农房</router-link>
					</li>
					<li class="highlight item " :class="{ 'active-menu' : currentActive == 4}">
						<router-link to="/woodland">林地</router-link>
					</li>
						<li class="highlight item " :class="{ 'active-menu' : currentActive == 9}">
						<router-link to="/otherland">其他土地</router-link>
					</li>
					<!-- <li class="highlight1 item item2" :class="{ 'active-menu' : currentActive == 4}">
						<router-link to="/farmmachinery">农机具</router-link>
					</li> -->
					<li class="highlight item " :class="{ 'active-menu' : currentActive == 5}">
						<router-link to="/finance">金融服务</router-link>
					</li>
					<!-- <li class="highlight item " :class="{ 'active-menu' : currentActive == 9}">
						<router-link to="">招拍挂</router-link>
					</li> -->
					<li class="highlight item hasChild"
						:class="[current == 1 ? 'active' : '', currentActive == 6 ? 'active-menu' : '']"
						@mouseenter="enter(1)" @mouseleave="leave()">
						<router-link to="/servicecentre/index">服务中心<span class="icon"></span></router-link>
						<ul>
							<li class="inner-item">
								<router-link to="/servicecentre/index">服务中心</router-link>
							</li>
							<li class="inner-item">
								<router-link to="/fineland">精品土地展</router-link>
							</li>
							<li class="inner-item">
								<router-link to="/servicecentre/joinus">加入我们</router-link>
							</li>
						</ul>
					</li>
					<li class="highlight1 item item2 hasChild" @mouseenter="enter(2)"
						:class="[current == 2 ? 'active' : '' ,currentActive == 8 ? 'active-menu' : '']"
						@mouseleave="leave()">
						<router-link to="/news" target="_self">土地资讯<span class="icon"></span></router-link>
						<div class="cols-repeat">
							<div class="clearfix cols-con">
								<ul class="col">
									<div class="clearfix cols-con">
										<ul class="col">
											<li class="inner-item">
												<router-link :to="'/news/lists?id=' + getNewsClassId('土地快讯')">土地快讯</router-link>
											</li>
											<li class="inner-item">
												<router-link :to="'/news/lists?id=' + getNewsClassId('土地百科')">土地百科</router-link>
											</li>
										</ul>
										<ul class="col">
											<li class="inner-item">
												<router-link :to="'/news/lists?id=' + getNewsClassId('国家政策')">国家政策</router-link>
											</li>
											<li class="inner-item">
												<router-link to="/ask">土地问答</router-link>
											</li>
										</ul>
									</div>
								</ul>
							</div>
						</div>
					</li>
					<li class="highlight1 item item2" :class="{ 'active-menu' : currentActive == 7}">
						<router-link to="/bi">土地数据</router-link>
					</li>
<!--					<li class="highlight1 item item2" :class="{ 'active-menu' : currentActive == 9}">
						<router-link to="/news/detail?id=1428530876595646465">赣抚农品</router-link>
					</li>-->
				</ul>
			</div>
		</div>
	</header>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	import city from "@/components/city/city.vue";
	export default {
		data() {
			return {
				current: -1,
				isAreaShow:false,
				keyword: ''
			}
		},
		components: {
			city
		},
		computed: {
			...mapGetters(['user','dict','location']),
			getNewsClassId() {
				return function (value) {
				   if(this.dict) {
					   if(this.dict.newsClass) {
						   let n = this.dict.newsClass.find(x=> x.text == value);
						   if(n) {
							   return n.id;
						   }
					   }
				   }
				   return '';
				}
			}

		},
		props: {
			isSnShow: {
				type: Boolean,
				default: true
			},
			currentActive: {
				type: [Number, String],
				default: -1
			}
		},
		methods: {
			signout() {
				this.$store.dispatch('user/signout');
			},
			enter(index) {
				this.current = index;
			},
			leave() {
				this.current = -1;
			},
			onChooseArea(a) {
				this.isAreaShow = false;
			   	let s = {
					id: '',
					city:'全国'
				}
				if(a.city.name == '市辖区') {
					s.city = a.province.name + '市'
				} else {
					s.city = a.city.name;
				}
				this.$store.commit('user/SET_LOCATION', s);
			},
			onSearch() {
				window.open('/#/supply/index?title=' + this.keyword,'_blank')
			}
		}
	}
</script>

<style scoped>
	.top-header {
		height: 45px;
		background-color: #f8f8f8;
		padding: 0 15px 0 15px;
	}

	.top-header .headpic {
		width: 30px;
		height: 30px;
		border-radius: 30px;
	}

	.top-header li {
		float: left;
		line-height: 45px;
		margin-right: 10px;
		position: relative;
	}

	.search-header {
		padding: 12px 15px 15px 15px;
		margin-top: auto;
		margin-bottom: auto;
		height: 80px;
		background: #FFFFFF;
	}

	.search-header .land-btn {
		height: 38px;
		background: #ECFFEC;
		border: 1px solid #00CC00;
		border-radius: 4px;
		line-height: 38px;
		padding: 0 30px 0 30px;
	}

	.search-header .search-group {
		margin-left: 10px;
		width: 370px;
		height: 38px;
		background: #F5F5F7;
		border: 1px solid #E7E7E7;
		border-radius: 4px;
		float: left;
	}

	.search-header .search-input {
		height: 35px;
		width: 286px;
		border: 0;
		background-color: #F5F5F7;
	}

	.search-header .s-btn {
		height: 38px;
		line-height: 38px;
		color: #FFFFFF;
		width: 70px;
		text-align: center;
		background: #00CC00;
		border-radius: 4px;
	}


	.nav-con {
		
		height: 44px;
		border-bottom: 2px solid #00CC00;
		background: #fff
	}

	.nav-con .nav-content {
		width: 1140px;
		margin: 0 auto;
		position: relative
	}

	.nav-con .nav-content .item {
		height: 44px;
		line-height: 44px;
		float: left;
		position: relative
	}

	.nav-con .nav-content .item a {
		display: inline-block;
		height: 100%;
		color: #444;
		font-size: 16px;
		text-decoration: none;
		padding: 0 34px 0 32px;
		border-top: 1px solid transparent
	}
	
	.nav-con .nav-content .item a.router-link-exact-active {
		color: #fff;
	}

	.nav-con .nav-content .item .inner-item a.router-link-exact-active {
		color: #00cc00;
	}

	.nav-con .nav-content .item a.hot {
		font-weight: 700
	}

	.nav-con .nav-content .item a:hover {
		color: #00CC00
	}

	.nav-con .nav-content .item.highlight1:hover>a {
		background: #00CC00;
		color: #fff
	}
	
	.nav-con .nav-content .item.highlight1.hasChild:hover>a {
		background: #fff;
		color: #00CC00
	}

	.nav-con .nav-content .item.highlight>a {
		color: #00CC00
	}

	.nav-con .nav-content .item.highlight>a:hover {
		background: #00CC00;
		color: #fff
	}

	.nav-con .nav-content .item.highlight:first-child {
		margin-right: 13px
	}

	.nav-con .nav-content .item.highlight.active-menu:first-child a {
		background: #00CC00;
		color: #fff
	}
	
	.nav-con .nav-content .item.hasChild.active-menu > a,
	.nav-con .nav-content .item.highlight.active-menu > a {
		color: #fff;
	}
	
	.active-menu {
		background: #00CC00;
		color: #fff;
	}
	
	.active-menu:first-child > a {
		background: #00CC00;
		color: #fff
	}

	.nav-con .nav-content .item.hasChild>a {
		border: 1px solid transparent;
		border-bottom: 0;
		height: 44px;
		z-index: 11
	}
	
	

	.nav-con .nav-content .item.hasChild>a span.icon {
		position: absolute;
		top: 50%;
		right: 5px;
		width: 9px;
		height: 7px;
		margin-top: -2px;
		display: inline-block;
		/*background: url('~@/assets/icon/arrow-down.png') no-repeat;*/
		background-size: 100%
	}

	.nav-con .nav-content .item.hasChild.item2>a span.icon {
		/*background: url('~@/assets/icon/arrow-up-black.png') no-repeat;*/
		transform: rotate(180deg);
		background-size: 100%;
		width: 9px;
		height: 7px;
		top: 22px
	}

	.nav-con .nav-content .item.active.highlight>a,
	.nav-con .nav-content .item.active.item2>a {
		border: 1px solid #00CC00;
		border-bottom: 0;
		background: #fff;
		color: #00CC00;
		position: relative
	}

	.nav-con .nav-content .item.active.highlight>a span.icon,
	.nav-con .nav-content .item.active.item2>a span.icon {
		transform: rotate(180deg)
	}

	.nav-con .nav-content .item.active.item2>a span.icon {
		/*background: url('~@/assets/icon/arrow-down.png') no-repeat;*/
		transform: rotate(180deg);
		background-size: 100%;
		width: 9px;
		height: 7px
	}

	.nav-con .nav-content .item.active>ul {
		display: block
	}

	.nav-con .nav-content .item.active>ul .inner-item a:hover {
		background: #f7f7f7;
		color: #00CC00
	}

	.nav-con .nav-content .item.active .cols-repeat {
		display: block
	}

	.nav-con .nav-content .item>ul {
		position: absolute;
		width: 100%;
		z-index: 10;
		left: -50%;
		margin-left: 50%;
		background: #fff;
		border-bottom: 1px solid #00CC00;
		display: none;
		margin-top: -2px
	}

	.nav-con .nav-content .item>ul .inner-item {
		border-left: 1px solid #00CC00;
		border-right: 1px solid #00CC00;
		height: 28px;
		line-height: 28px;
		font-size: 14px;
		text-align: center
	}

	.nav-con .nav-content .item>ul .inner-item a {
		height: 100%;
		width: 100%;
		display: inline-block;
		color: #555;
		padding: 0;
		font-size: 14px
	}

	.nav-con .nav-content .item>ul .inner-item a:hover {
		background: #f7f7f7
	}

	.nav-con .nav-content .item>ul:after {
		display: block;
		content: '';
		height: 10px;
		border-left: 1px solid #00CC00;
		border-right: 1px solid #00CC00
	}

	.nav-con .nav-content .item .cols-repeat {
		position: absolute;
		width: -webkit-max-content;
		width: -moz-max-content;
		width: max-content;
		z-index: 10;
		background: #fff;
		border: 1px solid #00CC00;
		display: none;
		margin-top: -2px;
		padding: 22px
	}

	.nav-con .nav-content .item .cols-repeat .cols-con {
		white-space: nowrap;
		display: -ms-inline-flexbox;
		display: inline-flex;
		float: left
	}

	.nav-con .nav-content .item .cols-repeat .col {
		float: left;
		margin-right: 33px
	}

	.nav-con .nav-content .item .cols-repeat .col .inner-item {
		margin-bottom: 18px;
		line-height: 1
	}

	.nav-con .nav-content .item .cols-repeat .col .inner-item a {
		font-size: 14px;
		padding: 0
	}

	.nav-con .nav-content .item .cols-repeat .col .inner-item:last-child {
		margin-bottom: 0
	}

	.nav-con .nav-content .item .cols-repeat .col:last-child {
		margin-right: 0
	}

	.nav-con .nav-content .nav-ul {
		float: left
	}

	.nav-con .nav-content .nav-ul:nth-child(2) {
		position: relative;
		margin-left: 10px
	}

	.nav-con .nav-content .nav-ul:nth-child(2):before {
		position: relative;
		width: 2px;
		height: 16px;
		background-color: #e7e7e7;
		content: '';
		display: inline-block;
		float: left;
		top: 14px;
		margin-right: 10px
	}

	.nav-con .nav-content .nav-openshop {
		position: absolute;
		display: none;
		width: 169px;
		height: 33px;
		right: 0;
		bottom: 5px;
		cursor: pointer
	}

	.nav-con .nav-content .nav-openshop img {
		width: 169px;
		height: 33px
	}

	.nav-con .nav-content .more-nav {
		float: right;
		position: relative
	}

	.nav-con .nav-content .more-nav .item2 {
		line-height: 50px
	}

	.nav-con .nav-content .more-nav .item2>a {
		padding: 0;
		line-height: 28px;
		font-size: 14px;
		text-align: center;
		height: 20px;
		padding-right: 8px;
		box-sizing: border-box;
		width: 62px;
		height: 28px;
		background: #f8f8f8;
		border: 1px solid #e6e6e6
	}

	.nav-con .nav-content .more-nav .item2>a:hover {
		color: #00CC00
	}

	.nav-con .nav-content .more-nav .item2>a>span.icon {
		width: 10px;
		height: 5px;
		top: 60%
	}

	.nav-con .nav-content .more-nav .item2>ul {
		z-index: 8;
		display: none;
		margin: 0;
		width: 290px;
		right: 0;
		position: absolute;
		left: auto;
		top: 44px;
		background: #f8f8f8;
		box-shadow: 0 5px 4px 0 rgba(0, 0, 0, .07);
		border: 1px solid #e6e6e6;
		box-sizing: border-box;
		line-height: 32px;
		padding: 10px 0
	}

	.nav-con .nav-content .more-nav .item2>ul:after {
		display: none
	}

	.nav-con .nav-content .more-nav .item2>ul>.inner-item {
		display: inline-block;
		border: 0;
		width: 95px
	}

	.nav-con .nav-content .more-nav .item2>ul>.inner-item:hover a {
		color: #00CC00
	}

	.nav-con .nav-content .more-nav:hover .item2>a {
		height: 35px;
		z-index: 9;
		position: relative;
		border-bottom: 0;
		background: #f8f8f8;
		color: #333 !important
	}

	.nav-con .nav-content .more-nav:hover .item2>a>span.icon {
		top: 45%;
		transform: rotate(0deg)
	}

	.nav-con .nav-content .more-nav:hover .item2>ul {
		display: block
	}

	.nav-con .nav-content .nav-app>a {
		color: #00CC00
	}

	.nav-con .nav-content .nav-app:hover>a {
		background: #00CC00;
		color: #fff
	}

	.nav-con .nav-content .nav-app:hover .nav-app-wrap {
		display: block
	}

	.nav-con .nav-content .nav-app .nav-app-wrap {
		display: none;
		z-index: 3;
		position: absolute;
		background: #fff;
		right: 0;
		border: 1px solid #ddd;
		box-shadow: 0 3px 8px #f0f0f0
	}

	.nav-con .nav-content .nav-app .top-qr-code {
		width: 280px;
		height: 116px;
		box-sizing: border-box;
		padding: 18px 0;
		margin: 0 18px;
		border-bottom: 1px dashed #ddd;
		position: relative;
		top: 1px
	}

	.nav-con .nav-content .nav-app .top-qr-code .header-top-btn {
		display: inline-block;
		margin-top: 8px;
		width: 80px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		box-sizing: border-box;
		border: 1px solid #ddd;
		background-color: #fff;
		color: #00CC00;
		font-size: 12px;
		padding: 0
	}

	.nav-con .nav-content .nav-app .top-qr-code .header-top-btn:hover {
		background-color: #00CC00;
		border-color: #00CC00;
		color: #fff
	}

	.nav-con .nav-content .nav-app .top-qr-code img {
		float: left;
		width: 80px;
		height: 80px
	}

	.nav-con .nav-content .nav-app .top-qr-code .right-info {
		float: left;
		padding-left: 12px;
		width: 200px;
		box-sizing: border-box
	}

	.nav-con .nav-content .nav-app .top-qr-code .qr-code-title {
		font-size: 14px;
		color: #333;
		line-height: 21px
	}

	.nav-con .nav-content .nav-app .top-qr-code .qr-code-desc {
		font-size: 12px;
		color: #999;
		line-height: 18px
	}

	@media screen and (max-width: 1200px) {
		.nav-con {
			padding: 0 15px 0 15px;
			border-bottom: 0;
		}

		.nav-con .nav-content {
			border-bottom: 2px solid #00CC00;
		}

		.top-header,
		.search-header {
			width: 1140px;
			max-width: 1140px;
		}

		.top-header .row,
		.search-header .row,
		.container {
			width: 1110px;
			max-width: 1110px;
		}
	}
</style>
