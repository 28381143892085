<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
    export default {
        methods: {
            getArea() {
                this.$store.dispatch('dict/getArea');
            },
            async getDict() {
                let dict = {
                    newsClass: [], //新闻分类
                    newsDisplay: [], //新闻显示
                    askClassList: [], //问答分类
                    priceUnit: [], //价格单位
                    acreageUnit: [], //面积单位
                    priceRangeW: [], //价格单位 万元
                    orderStatus:[],//订单状态
                    reserveStatus:[],//预约待看状态
                    needsStatus:[],//土地状态
                    landTags:[],//标签
                    areaRangeM: [{
                        id: '',
                        text: '全部',
                        measureAreaMin: '', //最小面积
                        measureAreaMax: '', //最大面积
                    }, {
                        id: 1,
                        text: '10亩以下',
                        measureAreaMin: 0, //最小面积
                        measureAreaMax: 11, //最大面积
                    }, {
                        id: 2,
                        text: '11~100亩',
                        measureAreaMin: 11, //最小面积
                        measureAreaMax: 101, //最大面积
                    }, {
                        id: 3,
                        text: '101~1000亩',
                        measureAreaMin: 101, //最小面积
                        measureAreaMax: 1001, //最大面积
                    }, {
                        id: 4,
                        text: '1001~5000亩',
                        measureAreaMin: 1001, //最小面积
                        measureAreaMax: 5001, //最大面积
                    }, {
                        id: 5,
                        text: '5001亩以上',
                        measureAreaMin: 5001, //最小面积
                        measureAreaMax: '', //最大面积
                    }],
                    areaRangeP: [{
                        id: '',
                        text: '全部',
                        measureAreaMin: '', //最小面积
                        measureAreaMax: '', //最大面积
                    }, {
                        id: 1,
                        text: '120平方米以下',
                        measureAreaMin: 0, //最小面积
                        measureAreaMax: 121, //最大面积
                    }, {
                        id: 2,
                        text: '121-200平方米',
                        measureAreaMin: 121, //最小面积
                        measureAreaMax: 201, //最大面积
                    }, {
                        id: 3,
                        text: '201-360平方米',
                        measureAreaMin: 201, //最小面积
                        measureAreaMax: 361, //最大面积
                    }, {
                        id: 4,
                        text: '361-1000平方米',
                        measureAreaMin: 361, //最小面积
                        measureAreaMax: 1001, //最大面积
                    }, {
                        id: 5,
                        text: '1001平方米以上',
                        measureAreaMin: 1001, //最小面积
                        measureAreaMax: '', //最大面积
                    }], //面积范围 平方米
                    years: [{
                        id: '',
                        text: '全部',
                        yearMin: '',
                        yearMax: '',
                    }, {
                        id: 1,
                        text: '5年及以下',
                        yearMin: 0,
                        yearMax: 6,
                    }, {
                        id: 2,
                        text: '6~10年',
                        yearMin: 6,
                        yearMax: 11,
                    }, {
                        id: 3,
                        text: '11~15年',
                        yearMin: 11,
                        yearMax: 16,
                    }, {
                        id: 4,
                        text: '16~20年',
                        yearMin: 16,
                        yearMax: 21,
                    }, {
                        id: 5,
                        text: '21~30年',
                        yearMin: 21,
                        yearMax: 31,
                    }, {
                        id: 6,
                        text: '31~40年',
                        yearMin: 31,
                        yearMax: 41,
                    }, {
                        id: 7,
                        text: '41~50年',
                        yearMin: 41,
                        yearMax: 51,
                    }, {
                        id: 8,
                        text: '50年以上',
                        yearMin: 51,
                        yearMax: '',
                    }], //年限范围
                    needsType: [], //需求类型
                    circulationType: [], //流转类型
                    appointmentType: [], //预约待看
                    reserveType:[],//预约类型
                    landUseType: [], //土地用途
                    landTags: [], //土地标签
                    banner: {
                        home: [],//首页广告
                        farmLand: [],//农用地右下广告
                        farmHouse: [],//农房右下广告
                        farmLandTop: [],//农用地顶部广告
                        farmHouseTop: [],//农房顶部广告
                        serviceCentre: [],//服务中心广告
                    },//广告信息
                    structure:  [
                        {
                            id: "1",
                            text: "木",
                        },
                        {
                            id: "2",
                            text: "砖",
                        },
                        {
                            id: "3",
                            text: "混合",
                        },
                        {
                            id: "4",
                            text: "框架",
                        },
                        {
                            id: "5",
                            text: "钢",
                        }
                    ],
                    decorationDegree:[
                        {
                            id: "1",
                            text: "毛坯",
                        },
                        {
                            id: "2",
                            text: "简单装修",
                        },
                        {
                            id: "3",
                            text: "精装修",
                        }
                    ],
                  forestcontractortype:[
                    {
                      id:"1",
                      text:"自留山",
                    },
                    {
                      id:"2",
                      text:"责任山",
                    },
                    {
                      id:"3",
                      text:"不清楚",
                    }
                  ]

                }
                this.$store.commit('dict/SET_DICT', dict);

                await this.$http.post(this.$api.dict.findDickTypeName, {typeName: 'CONTENT_TYPE'}).then((res) => {
                    dict.newsClass = res.result[0].dictionaryDetails.map(x => {
                        return {id: x.detailValue, text: x.text}
                    });
                })

                await this.$http.post(this.$api.dict.findDickTypeName, {typeName: 'PRICE_UNIT'}).then((res) => {
                    dict.priceUnit = res.result[0].dictionaryDetails.map(x => {
                        return {id: x.detailValue, text: x.text}
                    });
                });

                await this.$http.post(this.$api.dict.findDickTypeName, {typeName: 'ACREAGE_UNIT'}).then((res) => {
                    dict.acreageUnit = res.result[0].dictionaryDetails.map(x => {
                        return {id: x.detailValue, text: x.text}
                    });
                });

                await this.$http.post(this.$api.dict.findDickTypeName, {typeName: 'USER_NEEDS_TYPE'}).then((res) => {
                    dict.needsType = res.result[0].dictionaryDetails.map(x => {
                        return {id: x.detailValue, text: x.text}
                    });
                });

                await this.$http.post(this.$api.dict.findDickTypeName, {typeName: 'CIRCULATION_TYPE'}).then((res) => {
                    dict.circulationType = res.result[0].dictionaryDetails.map(x => {
                        return {id: x.detailValue, text: x.text}
                    });
                });

                await this.$http.post(this.$api.dict.findDickTypeName, {typeName: 'APPOINTMENT_TO_LOOK'}).then((res) => {
                    dict.appointmentType = res.result[0].dictionaryDetails.map(x => {
                        return {id: x.detailValue, text: x.text}
                    });
                    dict.appointmentType.unshift({
                        id: "",
                        text: "全部",
                    });
                });
              await this.$http.post(this.$api.dict.findDickTypeName, {typeName: 'RESERVE_TYPE'}).then((res) => {
                dict.reserveType = res.result[0].dictionaryDetails.map(x => {
                  return {id: x.detailValue, text: x.text}
                });
              });
              await this.$http.post(this.$api.dict.findDickTypeName, {typeName: 'BIZ_ORDER_STATUS'}).then((res) => {
                dict.orderStatus = res.result[0].dictionaryDetails.map(x => {
                  return {id: x.detailValue, text: x.text}
                });
              });
              await this.$http.post(this.$api.dict.findDickTypeName, {typeName: 'BIZ_RESERVE_STATUS'}).then((res) => {
                dict.reserveStatus = res.result[0].dictionaryDetails.map(x => {
                  return {id: x.detailValue, text: x.text}
                });
              });
              await this.$http.post(this.$api.dict.findDickTypeName, {typeName: 'BIZ_NEEDS_STATUS'}).then((res) => {
                dict.needsStatus = res.result[0].dictionaryDetails.map(x => {
                  return {id: x.detailValue, text: x.text}
                });
              });
                await this.$http.post(this.$api.dict.getLandUseType, {}).then((res) => {
                    dict.landUseType = res.result.map(x => {
                        return {
                            id: x.code,
                            text: x.name,
                            parentId: x.parentCode,
                            classId: x.classId,
                            children: x.children.map(y => {
                                return {id: y.code, text: y.name,classId:y.classId,parentId: y.parentCode}
                            })
                        }
                    });
                });
              await this.$http.post(this.$api.dict.getLandTags, {}).then((res) => {
                dict.landTags = res.result.map(x => {
                  return {
                    id: x.code,
                    level: x.level,
                    text:x.name,
                    parentId: x.parentCode,
                  }
                });
              });

                await this.$http.post(this.$api.dict.findDickTypeName, {typeName: 'CONTENT_SHOW_NODE'}).then((res) => {
                    dict.newsDisplay = res.result[0].dictionaryDetails.map(x => {
                        return {id: x.detailValue, text: x.text}
                    });
                });

                await this.$http.post(this.$api.ask.getClass, {searchParam: ''}).then(res => {
                    dict.askClassList = res.result;
                })
                //查询首页广告图片
                await this.$http.post(this.$api.banner.findBanner, {param: { location:1}}).then((res) => {
                    dict.banner.home = res.result;
                })
                //查询农用地顶部广告图片
                await this.$http.post(this.$api.banner.findBanner, {param: {location:2}}).then((res) => {
                    dict.banner.farmLandTop = res.result;
                })
                //查询农房顶部广告图片
                await this.$http.post(this.$api.banner.findBanner, {param: {location:6}}).then((res) => {
                    dict.banner.farmHouseTop = res.result;
                })
                //查询农用地右下广告图片
                await this.$http.post(this.$api.banner.findBanner, {param: {location:3}}).then((res) => {
                    dict.banner.farmLand = res.result;
                })
                //查询农房右下广告图片
                await this.$http.post(this.$api.banner.findBanner, {param: {location:7}}).then((res) => {
                    dict.banner.farmHouse = res.result;
                })
                //查询服务中心广告图片
                await this.$http.post(this.$api.banner.findBanner, {param: {location:8}}).then((res) => {
                    dict.banner.serviceCentre = res.result;
                })
                this.$store.commit('dict/SET_DICT', dict);
            }
        },
        created() {
            this.getArea();
            this.getDict();
        },
    };
</script>


<style>
    @import url("~@/assets/css/base.css");
    @import url("~@/assets/css/icon.css");
</style>
