import Vue from 'vue'
let _area = null;
let _dict = null;
const state = {
    area: _area,
    dict: _dict,
}

const mutations = {
    SET_AREA: (state, area) => {
        state.area = area;
    },
    SET_DICT: (state, dict) => {
        state.dict = dict;
    }
}

const actions = {
    getArea({
        commit,
        state
    }) {
        return new Promise((resolve, reject) => {
            Vue.prototype.$http.post(Vue.prototype.$api.area.getList, { param: {} }).then((res) => {
                if (res.code == 1) {
                    if (res.result.length > 0) {
                        let m = {
                            province: [{
                                id: '',
                                name: '全部',
                                code: ''
                            }],
                            city: [{
                                id: '',
                                name: '全部',
                                code: ''
                            }],
                            area: [{
                                id: '',
                                name: '全部',
                                code: ''
                            }],
                        };
                        res.result.forEach((x) => {
                            if (x.level == 1) {
                                x.name = x.name.includes('内蒙古') ? x.name.substr(0,3): x.name.substr(0,2)
                                m.province.push(x);
                            }
                            if (x.level == 2) {
                                m.city.push(x);
                            }
                            if (x.level == 3) {
                                m.area.push(x);
                            }
                        });
                        commit('SET_AREA', m);
                    }
                }
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
