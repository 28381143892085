import Vue from 'vue'
let _token = localStorage.getItem('token') || ''
let _member = localStorage.getItem('member') || null
let _location = localStorage.getItem('location') || null
const state = {
	token: _token,
	member: _member ? JSON.parse(_member) : _member,
	location: _location ? JSON.parse(_location) : _location,
}

const mutations = {
	SET_TOKEN: (state, token) => {
		state.token  = token;
		localStorage.setItem('token', token);
	},
	SET_MEMBER: (state, member) => {
		state.member  = member;
		localStorage.setItem('member', JSON.stringify(member));
	},
	SET_LOCATION: (state, location) => {
		state.location = location;
		localStorage.setItem('location', JSON.stringify(location));
	}
}

const actions = {
	signout({
		commit,
		state
	}) {
		return new Promise((resolve, reject) => {
			Vue.prototype.$http.post(Vue.prototype.$api.user.signout).then(() => {
				commit('SET_TOKEN', '')
				commit('SET_MEMBER', null)
				resolve()
			}).catch(error => {
				reject(error)
			})
		})
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
