/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios';
import store from '@/store/index'
import model from '@/assets/js/model'
import router from '@/router'

// 环境的切换
axios.defaults.baseURL = process.env.VUE_APP_URL

// 请求超时时间
axios.defaults.timeout = 10000;

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.defaults.headers.delete['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截器
axios.interceptors.request.use(
	config => {
		config.headers['X-Requested-With'] = 'XMLHttpRequest';
		// 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
		// 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
		const token = store.getters.token;
		token && (config.headers.token = token);
		config.headers['x-ca-reqid'] = getGenerateUUID();
		config.headers['x-ca-reqtime'] = Date.parse(new Date());
		return config;
	},
	error => {
		return Promise.error(error);
	})

// 响应拦截器
axios.interceptors.response.use(
	response => {
		if (response.status === 200) {
			if(response.data.code === 1) {
				return response;
			} else {
				model.toast(response.data.reason, 1500);
			}
		} else {
			model.toast('服务器繁忙，请稍后再试', 1500);
			return response;
		}
	},
	// 服务器状态码不是200的情况    
	error => {
		let response = error.response;
		if(response.status === 401) {
			router.push({
				path: '/login',
				query: {
					redirect: router.currentRoute.fullPath
				}
			});
		} else if(response.status === 403) {
			store.commit('logout');
			// 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
			setTimeout(() => {
				router.push({
					path: '/login',
					query: {
						redirect: router.currentRoute.fullPath
					}
				});
			}, 1000);
		} else {
			model.toast(response.data.reason, 1500);
			return false;
		}
	    return Promise.reject(response);
	}
);

function getGenerateUUID() {
    var d = new Date().getTime();
    if (window.performance && typeof window.performance.now === "function") {
        d += performance.now(); //use high-precision timer if available
    }
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
}

export default {
	/** 
	 * get方法，对应get请求 
	 * @param {String} url [请求的url地址] 
	 * @param {Object} params [请求时携带的参数] 
	 */
	get: function (url, params) {
		return new Promise((resolve, reject) => {
			axios.get(url, {
					params: params
				})
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err)
				})
		});
	},
	/** 
	 * post方法，对应post请求 
	 * @param {String} url [请求的url地址] 
	 * @param {Object} params [请求时携带的参数] 
	 */
	post: function (url, params) {
		return new Promise((resolve, reject) => {
			axios.post(url, params)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err)
				})
		});
	},

	delete: function (url, params) {
		return new Promise((resolve, reject) => {
			axios.delete(url, {data: params})
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err)
				})
		});
	},

	/** 
	 * post方法，对应post请求 
	 * @param {String} url [请求的url地址] 
	 * @param {Object} params [请求时携带的参数] 
	 */
	upload: function (url, key, params) {
		let config = {
			headers: {'Content-Type': 'multipart/form-data'}
		}
		let _params = new FormData()  // 创建form对象
		if(params.constructor === Array) {
			params.forEach(p=> { 
				_params.append(key, p.file) 
			});
		} else {
			_params.append(key, params.file)  
		}
		
		return new Promise((resolve, reject) => {
			axios.post(url, _params, config)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err)
				})
		});
	}
}
