/**
 *  提示弹窗
 */
export default {
  // 提示弹窗，无按钮，延迟关闭
  toast: function (msg, duration) {
    duration = isNaN(duration) ? 2000 : duration;
    var m = document.createElement('div')
    m.innerHTML = msg
    m.style.cssText = "width: 20%; padding: 10px;opacity: 0.7;color: rgb(255, 255, 255);text-align: center;border-radius: 5px;position: fixed;top: 40%;left: 40%;z-index: 999999;background: rgb(17, 17, 17);font-size: 12px;"
    document.body.appendChild(m)
    if(timer) {
      clearTimeout(timer)
    }
    var timer = setTimeout(function () {
      var d = 0.5;
      m.style.webkitTransition = '-webkit-transform ' + d + 's ease-in, opacity ' + d + 's ease-in'
      m.style.opacity = '0'
      if(cssTimer) {
        clearTimeout(cssTimer)
      }
      var cssTimer = setTimeout(function () {
        document.body.removeChild(m)
      }, d * 1000)
    }, duration)
  },

};